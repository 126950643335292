/*
 * @Author: v-fmasoud@tableau.com
 * @Date: 2017-11-16 21:26:33
 * @Last Modified by: v-fmasoud@tableau.com
 * @Last Modified time: 2018-01-19 09:37:44
 */
import { HELP_ROUTE } from '../routes';
import { Headers, ErrorCode } from './Constants';
import i18n from './i18n';

const t = i18n.t.bind(i18n);

export const getChangePasswordErrorMessage = (error) => {
  let errorMessage = null;
  if (error.response) {
    const requestId = error.response.headers[Headers.requestId];
    switch (error.response.data.code) {
      case ErrorCode.USER_DEACTIVATED:
        errorMessage = [
          () => t(
            'Your account has been deactivated. '
                + 'Please contact our <a href="{{link}}">Customer Service team</a> for help.',
            { link: HELP_ROUTE },
          ),
        ];
        break;
      case ErrorCode.BAD_PASSWORD:
        errorMessage = ['The password you entered does not match our records.', 'Please try again.'];
        break;
      case ErrorCode.UNAUTHORIZED:
      case ErrorCode.FORBIDDEN_VIEW_EMAIL:
        errorMessage = ["We're sorry. You are not authorized to access the requested page or URL."];
        break;
      case ErrorCode.NOT_FOUND:
        errorMessage = ['The Tableau account could not be updated, the request was not found.'];
        break;
      case ErrorCode.PASSWORD_HISTORY_ERROR:
        errorMessage = ['This password has previously been used. Try another.'];
        break;
      case ErrorCode.INVALID_TABLEAU_ONLINE_SERVER_ADMIN_PASSWORD:
        errorMessage = ['New password should be at least 16 characters long.'];
        break;
      default:
        errorMessage = ["We're sorry! An unknown error has occurred.", '<br />', `RequestId: ${requestId}`];
        break;
    }
  } else {
    errorMessage = ["We're sorry! An unknown error has occurred."];
  }
  return errorMessage;
};

export const getChangeEmailErrorMessage = (error) => {
  let errorMessage = null;
  if (error.response) {
    const requestId = error.response.headers[Headers.requestId];
    switch (error.response.data.code) {
      case ErrorCode.USER_DEACTIVATED:
        errorMessage = ['The account associated with the current email has been deactivated.'];
        break;
      case ErrorCode.CHANGE_EMAIL_ADMIN_NOT_ALLOWED:
        errorMessage = [
          'This account belongs to an admin group and for security reasons '
            + 'cannot change to a new email address. Please remove this person from admin groups '
            + 'before changing to the new address.',
        ];
        break;
      case ErrorCode.CHANGE_EMAIL_TABLEAU_ONLY:
      case ErrorCode.CHANGE_EMAIL_TABLEAU:
        errorMessage = [
          'For security reasons, email addresses that currently belong to the '
            + 'tableausoftware.com or tableau.com domains may only be changed to another '
            + 'tableausoftware.com or tableau.com email address.',
        ];
        break;
      case ErrorCode.EMAIL_ALREADY_EXISTS:
        errorMessage = ['That email address is already assigned to an existing Tableau account.'];
        break;
      case ErrorCode.FORBIDDEN_VIEW_EMAIL:
        errorMessage = ["We're sorry. You are not authorized to access the requested page or URL."];
        break;
      case ErrorCode.FORBIDDEN_EMAIL_EMBARGOED_COUNTRY:
        errorMessage = ['Accounts with email addresses from restricted regions are not allowed. For more information, see Salesforce <a href="https://www.salesforce.com/company/legal/compliance/">Compliance</a>.'];
        break;
      default:
        errorMessage = ["We're sorry! An unknown error has occurred.", '<br />', `RequestId: ${requestId}`];
        break;
    }
  } else {
    errorMessage = ["We're sorry! An unknown error has occurred."];
  }
  return errorMessage;
};

export const getHelpErrorMessage = (error) => {
  let errorMessage = null;
  if (error.response) {
    const requestId = error.response.headers[Headers.requestId];
    switch (error.response.data.code) {
      default:
        errorMessage = ["We're sorry! An unknown error has occurred.", '<br />', `RequestId: ${requestId}`];
        break;
    }
  } else {
    errorMessage = ["We're sorry! An unknown error has occurred."];
  }

  return errorMessage;
};
