import PropTypes from 'prop-types';
import React from 'react';
import { translate } from 'react-i18next';
import { MessageType, TokenTypes } from '../common/Constants';
import { sendAdminInstructions } from '../common/Util';
import { HELP_ROUTE } from '../routes';
import Message from '../components/Message';
import ResendToken from './ResendToken';

const Unauthorized = (props) => {
  const { t, message, email } = props;
  document.title = t('Tableau - Account Management');
  if (message === 'unverified') {
    return (
      <ResendToken
        externalError={t('Your account is pending activation. Enter your email to recreate your activation email.')}
        tokenType={TokenTypes.RESEND_ACTIVATION}
      />
    );
  }
  if (message === 'deactivated') {
    return (
      <section className="section">
        <div className="content-container" id="errorMessage">
          <Message
            id="unauthorized"
            text={t(
              'Your account has been deactivated. Please contact our <a href="{{link}}">Customer Service team</a> for help.',
              { link: HELP_ROUTE },
            )}
            messageType={MessageType.error}
          />
        </div>
      </section>
    );
  }
  if (message === 'admin') {
    sendAdminInstructions(email);
    return (
      <section className="section">
        <div className="content-container" id="errorMessage">
          <Message
            id="admin_blocked"
            text={t(
              'For security reasons your login attempt has been blocked. Please check your email for instructions on how to access your account.',
            )}
            messageType={MessageType.error}
          />
        </div>
      </section>
    );
  }

  if (message === 'sfOrgNotVerified') {
    return (
      <section className="section">
        <div className="content-container" id="errorMessage">
          <Message
            id="unauthorized"
            text={t(
              'You have not linked your salesforce account to Tableau. Please check your email for instructions on how to link your account.',
            )}
            messageType={MessageType.error}
          />
        </div>
      </section>
    );
  }

  if (message === 'restricted') {
    return (
      <section className="section">
        <div className="content-container" id="errorMessage">
          <Message
            id="unauthorized"
            text={t(
              'Accounts with email addresses from restricted regions are not allowed. For more information, see Salesforce <a href="https://www.salesforce.com/company/legal/compliance/">Compliance</a>.',
            )}
            messageType={MessageType.error}
          />
        </div>
      </section>
    );
  }

  return (
    <section className="section">
      <div className="content-container" id="errorMessage">
        <Message
          id="unauthorized"
          text={t("We're sorry. You are not authorized to access the requested page or URL.")}
          messageType={MessageType.error}
        />
      </div>
    </section>
  );
};

Unauthorized.propTypes = {
  t: PropTypes.func.isRequired,
  message: PropTypes.string,
  email: PropTypes.string,
};

Unauthorized.defaultProps = {
  message: null,
  email: null,
};

export default translate()(Unauthorized);
